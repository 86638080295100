/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  IRaffles,
  IRafflesInterface,
} from "../../../contracts/interfaces/IRaffles";

const _abi = [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
    ],
    name: "getRound",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "id",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "startTime",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "duration",
            type: "uint256",
          },
          {
            internalType: "bool",
            name: "isClosed",
            type: "bool",
          },
          {
            internalType: "bool",
            name: "isOracleFulfilled",
            type: "bool",
          },
          {
            internalType: "bool",
            name: "isFinished",
            type: "bool",
          },
          {
            internalType: "uint256",
            name: "initialPrize",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "totalPrize",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "maxTicketsFromOneMember",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "tokensForOneTicket",
            type: "uint256",
          },
          {
            internalType: "uint256[]",
            name: "winnersForLevel",
            type: "uint256[]",
          },
          {
            internalType: "uint256[]",
            name: "prizeForLevel",
            type: "uint256[]",
          },
          {
            internalType: "uint256",
            name: "totalTickets",
            type: "uint256",
          },
          {
            internalType: "address[]",
            name: "members",
            type: "address[]",
          },
          {
            internalType: "uint256",
            name: "randomWord",
            type: "uint256",
          },
          {
            internalType: "address[][]",
            name: "winners",
            type: "address[][]",
          },
        ],
        internalType: "struct IRaffles.Round",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "roundId",
        type: "uint256",
      },
    ],
    name: "getUserRoundEntry",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
] as const;

export class IRaffles__factory {
  static readonly abi = _abi;
  static createInterface(): IRafflesInterface {
    return new utils.Interface(_abi) as IRafflesInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IRaffles {
    return new Contract(address, _abi, signerOrProvider) as IRaffles;
  }
}
